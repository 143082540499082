import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"

import { LazyLoadComponent } from "react-lazy-load-image-component"

// import QuandaryConnectBanner from "../images/new-images/quandary-connect-banner.jpg"

import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Banner from "../components/hero/connect-banner.js"

import { AiOutlineRise } from "react-icons/ai"
import { RiTimerFlashLine } from "react-icons/ri"
import { BiLock } from "react-icons/bi"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const Testimonials = loadable(() =>
  pMinDelay(import("../components/swiper/testimonials-new"), 500)
)
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)
const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))

class QuandaryConnectPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} banner={<Banner />}>
        <Seo
          title="System Integration Services | Quandary Consulting Group"
          description="Our Denver-based team provides custom integrations and workflow mapping that drives continuous process improvement for mid-market and enterprise."
        />
        <div className="page-content pt-0 pb-0">
          <PartnerSwiper />
          <section style={{ padding: "3rem 0", backgroundColor: "#e7e7e7" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2>Connect Your Business Systems</h2>
                  <p className="mt-4">
                    As your business grows, you need more applications to
                    operate. But these disparate systems typically don't talk to
                    each other, putting you in a bind. Employees don't want to
                    do manual data entry. It wastes time. Spreadsheets are a
                    nightmare. And the pressure of mistakes is enough to push
                    them out the door. Not to mention how much slower (and more
                    expensive) all this makes running your business...
                  </p>
                  <p>
                    Imagine a business where all your applications speak to one
                    another effortlessly. No manual data entry. No delays in
                    reporting. No costly mistakes. Quandary Connect transforms
                    your business with integration services designed to save you
                    hours and hours of manual tasks. Don’t let slow,
                    disconnected systems slam the breaks on your success. Get
                    real-time insights at a glance with more accurate data and
                    reporting.
                  </p>

                  <div className="text-left my-2">
                    <Link
                      className="btn btn-primary btn-arrow mt-3"
                      to="/contact/"
                    >
                      Let's Chat
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 pt-5">
                  <div className="text-center">
                    <LazyLoadComponent>
                      <iframe
                        width="100%"
                        height="400px"
                        src="https://www.youtube.com/embed/hTiJrEJLvYM"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="ByeSpreadSheets"
                        className="rounded"
                      />
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="bg-white quandary-connect-glance"
            style={{ padding: "3rem 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2>Transform Workflows with System Integrations</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 mt-4 text-center">
                  <RiTimerFlashLine />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Real-Time Reporting
                  </p>
                  <p>
                    Get the most accurate data delivered exactly when you need
                    it.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 mt-4 text-center">
                  <AiOutlineRise />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Increase productivity
                  </p>
                  <p>
                    Replace boring, time-wasting tasks with seamless automation.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 mt-4 text-center">
                  <BiLock />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Stay Secure
                  </p>
                  <p>
                    Transfer data securely between systems without creating
                    costly errors.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="case-studies-container">
            <div id="case-studies" className="bg-indigo">
              <div className="container">
                <div className="intro text-center">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                        Case Studies
                      </p>
                      <h2 className=" my-2 text-white">
                        We Save Clients 1000s of Hours. Every Year.
                      </h2>
                      {/* <p className="text-white">
                        We helped a national healthcare provider save over 9,000
                        hours in weekly labor costs by developing a single
                        application...
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
              {/* </Suspense> */}
            </div>
          </section>
          <section
            id="it-consulting"
            className="it-consulting"
            style={{ padding: "3rem 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-8 mx-auto text-center">
                  <h2 className="font-weight-medium">
                    How System Integration Supports Your Needs
                  </h2>
                  <p className=""></p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                  marginTop: "3rem",
                }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                  <h3>Connect Your Entire Application Stack</h3>
                  <p className="mt-4">
                    We build custom integrations between your applications using
                    low code. Our expert developers can build the data
                    infrastructure you need for real-time, accurate reporting.
                    Connect all your applications: Workday, Slack, Netsuite,
                    SAP, custom software, and more!
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/custom-data-systems.jpg"
                    alt="Custom data systems integration"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/cost-effective.jpg"
                    alt="Cost-Effective"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Get Smarter Workflows</h3>
                  <p className="mt-4">
                    Whether you need to integrate your CRM into your ERP or you
                    want to streamline communication between your team and your
                    customers, we help you design a more efficient way of doing
                    business. Transform how work gets done in your organization
                    with smarter workflows that give your staff more time to
                    focus on essential tasks.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                  <h3>Discover Actionable Insights</h3>
                  <p className="mt-4">
                    Transfer data seamlessly between all your applications and
                    get a better view of your customer, their needs, and where
                    the market is heading. We build the data infrastructure you
                    need to access better insights at a glance with clear
                    reporting designed to help you stay agile.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/quandary-connect-img1.png"
                    alt="Cost-Effective"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <LazyLoadComponent>
              <Testimonials />
            </LazyLoadComponent>
          </section>
          <section
            style={{
              padding: "3rem 0",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12  text-center">
                  <h2 className="mb-3 text-left-mobile">
                    It's Time to Kill Manual Data Entry
                  </h2>
                  <p className="text-dark text-left-mobile">
                    Integrate your application stack and eliminate expensive
                    (and time-consuming) tasks like manual data entry.
                  </p>
                  <Link
                    className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                    to="/contact/"
                  >
                    Speak to a Technology Consultant
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="bg-gradient-gray py-5">
            <div className="container">
              <div className="intro text-center">
                <h3 className="m-0">
                  Put your business on autopilot with Quandary
                </h3>
              </div>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default QuandaryConnectPage

export const quandaryConnectPageQuery = graphql`
  query quandaryConnectPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
